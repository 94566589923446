import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-client-details-modal',
  templateUrl: './client-details-modal.component.html',
  styleUrls: ['./client-details-modal.component.scss']
})
export class ClientDetailsModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ClientDetailsModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log(this.data)
  }

  onOK() {
    this.dialogRef.close(true);
  }

}
