<div class="centerForm">
    <div style="position: absolute; z-index: 1;">
        <!-- <app-table-filters (selectedFiltersSend)="selectedFilters($event)"></app-table-filters> -->
        <div style="display: flex; justify-content: flex-start; gap: 1em;" [formGroup]="filter_table">
            <mat-form-field style="width: 100%;">
                <mat-label>Created at</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="created_at"
                    (dateChange)="applyFilter($event.value,'created_at')">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>File Status</mat-label>
                <mat-select formControlName="file_status" (selectionChange)="applyFilter($event.value,'rapid_status')">
                    <mat-option *ngFor="let item of statuses;" [value]="item.insertValue">{{ item.displayValue
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%;" *ngIf="dptDisplay">
                <mat-label>Department</mat-label>
                <mat-select formControlName="department_name"
                    (selectionChange)="applyFilter($event.value,'department_name')"
                    (selectionChange)="getDepartmentEmployees($event.value)">
                    <mat-option *ngFor="let item of departmentList;"
                        [value]="item.department_name">{{item.department_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%;" *ngIf="byDisplay">
                <mat-label>Created by</mat-label>
                <mat-select formControlName="created_by" (selectionChange)="applyFilter($event.value,'full_name')">
                    <mat-option *ngFor="let item of employeeNameList;"
                        [value]="item.full_name">{{item.full_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <span style="text-align: center; color: rgb(108, 110, 111);">
                <mat-icon style="cursor: pointer;" (click)="resetFilters()" title="Reset filters">replay</mat-icon>
                <sup>reset</sup>
            </span>
            <span style="text-align: center;color: rgb(108, 110, 111);">
                <mat-icon style="cursor: pointer;" (click)="onBtnExport()" title="Download">get_app</mat-icon>
                <sup>download</sup>
            </span>
        </div>
    </div>
</div>
<div class="centerForm">
    <div class="d-flex justify-content-end">
        <button style="margin-bottom: 10px;" mat-raised-button color="primary" (click)="openNewApp()">New
            Application</button>
    </div>
    <div style="height: calc(100% - 30px);">
        <ag-grid-angular style="width: 100%; height: 850px;" class="ag-theme-material" [rowData]="rowData"
            [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [pagination]="true" [animateRows]="true"
            [rowHeight]="45" [paginationPageSize]="20" (rowDoubleClicked)="openMoreDetails($event)"
            [defaultCsvExportParams]="defaultCsvExportParams"
            [isExternalFilterPresent]="isExternalFilterPresent.bind(this)"
            [doesExternalFilterPass]="doesExternalFilterPass.bind(this)" (gridReady)="onGridReady_RapidData($event)">
        </ag-grid-angular>
    </div>
</div>