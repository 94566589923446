import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-cell-renderer',
  templateUrl: './button-cell-renderer.component.html',
  styleUrls: ['./button-cell-renderer.component.scss']
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  constructor() { }
  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(e: any) {
    this.params.clicked(this.params?.data);
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
