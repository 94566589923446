<h2 style="text-align: center;" mat-dialog-title>{{data?.first_name}} {{data?.last_name}}</h2>
<div mat-dialog-content>
    <table>
        <tr>
            <td class="contentFormat">Middle Name :</td>
            <td>{{data?.middle_name}}</td>
        </tr>
        <tr>
            <td class="contentFormat">DOB :</td>
            <td>{{data?.dob}}</td>
        </tr>
        <tr>
            <td class="contentFormat">SIN :</td>
            <td>{{data?.sin}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Email :</td>
            <td>{{data?.email_address}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Mobile# :</td>
            <td>{{data?.telephone}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Street :</td>
            <td>{{data?.street}}</td>
        </tr>
        <tr>
            <td class="contentFormat">City :</td>
            <td>{{data?.city}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Province :</td>
            <td>{{data?.province}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Postal Code :</td>
            <td>{{data?.postal_code}}</td>
        </tr>
        <tr>
            <td class="contentFormat">EDP Number :</td>
            <td>{{data?.edp_number}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Agent (ext) :</td>
            <td>{{data?.referral_agent_ext}}</td>
        </tr>
        <tr>
            <td class="contentFormat">Comments :</td>
            <td>{{data?.comments}}</td>
        </tr>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onOK()">OK</button>
</div>