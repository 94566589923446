<h1 mat-dialog-title>Credit report not found</h1>
<div mat-dialog-content>
    <p style="text-align: justify;">Please confirm the following are correct:<br>
        <label><span>First name</span>: {{data?.appInfo.first_name}}</label><br>
        <label><span>last name</span>: {{data?.appInfo.last_name}}</label><br>
        <label><span>Date of Birth </span><span style="font-size: medium; font-weight: bold;">(MM/DD/YYYY)</span>:
            {{data?.appInfo.dob | date: 'MM/dd/yyyy'}}</label><br>

        <label><span>Postal Code</span>: {{data?.appInfo.postal_code}}</label><br><br>
        Use "Show more information" link for additional fields. Using optional fields like SIN, province etc can
        increase the
        likelihood to get the correct credit report.<br>
        List of optional fields:<br>
        <span>1. Middle name</span><br>
        <span>2. SIN</span><br>
        <span>3. Address</span><br>
        <span>4. City</span><br>
        <span>5. Province</span>
    </p>
</div>
<div mat-dialog-actions>
    <button style="float: right;" mat-button (click)="onOK()">Close</button>
</div>