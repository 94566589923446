<h1 mat-dialog-title>Note</h1>
<div mat-dialog-content>
    <mat-spinner [diameter]="30" *ngIf="!notesData?.note"></mat-spinner>
    <p>{{notesData?.note_subject}} - {{notesData?.description}}</p>
    <!-- <p style="text-align: justify;">{{notesData?.note}}</p> -->
    <p *ngIf="notesData?.note !== 'No Notes!'" style="font-size:medium; font-weight: bold;">
        [{{notesData?.note_date}}]</p>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="onOK()">OK</button>
</div>