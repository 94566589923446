import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  sessionID: string = '';
  returnUrl: string = '/';
  appVersion = environment.version;

  login_form = new FormGroup({
    user: new FormControl('', Validators.required),
    pass: new FormControl('', Validators.required)
  });

  constructor(private apiService: ApiServiceService, private router: Router) { }

  ngOnInit(): void {
  }

  userLogin() {
    this.generateUniqueID();
    let info = {
      user: this.login_form.get('user')?.value,
      pass: this.login_form.get('pass')?.value,
      sessionid: this.sessionID
    };

    this.apiService.verify_signin(info).subscribe(async (res: any) => {
      if (res.message != 'Success') {
        alert(res.message);
      } else {
        this.apiService.updateSession(info).subscribe((res: any) => {
          if (res.message == 'Success') {
            //Update the table with sessionID
            localStorage.setItem('SESSIONID', this.sessionID);
            if (this.returnUrl == '/') {
              this.router.navigate(['dashboard']); //
            } else {
              this.router.navigateByUrl(this.returnUrl); // open the url that the session got expired
            }
            this.apiService.setLoggedIn(true);
          }
        });
      }
    })
  }

  generateUniqueID() {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let uniqueLength = 41;
    this.sessionID = '';
    for (let i = 0; i < uniqueLength; i++) {
      let randNumber = Math.floor(Math.random() * chars.length);
      this.sessionID += chars.substring(randNumber, randNumber + 1);
    }
  }

}



