import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-inverite-modal',
  templateUrl: './inverite-modal.component.html',
  styleUrls: ['./inverite-modal.component.scss']
})
export class InveriteModalComponent implements OnInit, AfterViewInit, OnDestroy {
  province_names = [
    { provinceID: 'ON', name: 'Ontario' },
    { provinceID: 'NS', name: 'Nova Scotia' },
    { provinceID: 'NB', name: 'New Brunswick' },
    { provinceID: 'MB', name: 'Manitoba' },
    { provinceID: 'BC', name: 'British Columbia' },
    { provinceID: 'PEI', name: 'Prince Edward Island' },
    { provinceID: 'SK', name: 'Saskatchewan' },
    { provinceID: 'AB', name: 'Alberta' },
    { provinceID: 'NL', name: 'Newfoundland and Labrador' },
    { provinceID: 'NT', name: 'Northwest Territories' },
    { provinceID: 'NU', name: 'Nunavut' },
    { provinceID: 'YT', name: 'Yukon' }
  ]
  bankDataSubscription: Subscription | undefined;

  banking_list: any;
  inverite_form = new FormGroup({
    bank_names: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required)
  });

  client_banks: any;
  isSourceButton: boolean | undefined;
  btnSubmitSpinner = false;
  btnSubmitShowText = true;
  isloading = true;

  // application_id = '2390707000034288237';

  constructor(public dialogRef: MatDialogRef<InveriteModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiServiceService) { }

  ngAfterViewInit(): void {
    this.bankDataSubscription = this.apiService.getInveriteBanks().subscribe(async (res: any) => {
      this.banking_list = res?.banks;
      this.client_banks = await this.getAllClientBanks(this.data?.applicationId, 'primary');
    })
  }

  ngOnInit(): void {
    this.isSourceButton = this.data?.isButton;
  }

  submitBankRequest() {
    this.loadSubmitResult();
    this.apiService.isExistingInverite(this.data?.applicationId, 'primary', this.inverite_form.get('bank_names')?.value).subscribe((res: any) => {
      if (res?.status) {
        const request_data = {
          bankID: this.inverite_form.get('bank_names')?.value,
          email: this.data?.email,
          firstname: this.data?.firstName,
          lastname: this.data?.firstName,
          phone: this.data?.phone
        };
        if (res?.isExist) {
          if (confirm('A bank request has already been created for this client, would you like to create another one?')) {
            // console.log("Existing");
            this.createRapidInveriteRequest(1, request_data);
          } else {
            this.inverite_form.reset();
            this.resultSubmitCompleted();
          }
        } else {
          // console.log("New");
          this.createRapidInveriteRequest(0, request_data);
        }
      } else {
        console.log("Data input error");
      }
    })
  }

  /**
   * 
   * @param acc_check 1 banking exist. 0 new banking
   * @param requestData 
   */
  createRapidInveriteRequest(acc_check: number, requestData: any) {
    this.apiService.createRapidInveriteRequest(requestData).subscribe(((res: any) => {
      if (res.hasOwnProperty('request_guid')) {
        let inverite_data = {
          acc_check: acc_check,
          iframeurl: res['iframeurl'],
          guid: res['request_guid'],
          application_id: this.data?.applicationId,
          username: res['username'],
          email: this.data?.email,
          first_name: this.data?.firstName,
          last_name: this.data?.lastName,
          phone_number: this.data?.phone,
          bank_name: this.getSelectedOptionText(this.inverite_form.get('bank_names')?.value, this.banking_list, 'bankID', 'name'),
          bank_id: this.inverite_form.get('bank_names')?.value,
          province: this.getSelectedOptionText(this.inverite_form.get('province')?.value, this.province_names, 'provinceID', 'name'),
          application_type: 'primary',
          employeeId: this.data?.employeeId
        };
        this.apiService
          .updateRapidInverite(inverite_data)
          .subscribe(async (res) => {
            this.client_banks = await this.getAllClientBanks(this.data?.applicationId, 'primary');
            this.inverite_form.reset();
            this.resultSubmitCompleted();
          })
      } else {
        console.log('Unable to create bank request');
        this.resultSubmitCompleted();
      }
    }))
  }

  /**
   * 
   * @param selectedId selected value
   * @param dataSource array  
   * @param fieldId array value id
   * @param fieldName array value name
   * @returns display text of the select option
   */
  getSelectedOptionText = (selectedId: number, dataSource: any, fieldId: string, fieldName: string) => {
    return dataSource.find((option: any) => option[fieldId] === selectedId)[fieldName];
  }

  getAllClientBanks(applicationId: string, appType: string) {
    return new Promise((resolve, reject) => {
      this.apiService.getAllClientBanks(applicationId, appType).subscribe((res: any) => {
        if (res?.status === true && res?.isExist === true) {
          this.isloading = false;
          resolve(res?.data);
        } else {
          this.isloading = false;
          resolve([]);
        }
      })

    })
  }

  deleteGuid(guid: string) {
    if (confirm('Do you want to delete the bank?')) {
      this.apiService.deleteGuid(guid).subscribe(async (res) => {
        this.client_banks = await this.getAllClientBanks(this.data?.applicationId, 'primary');
      })
    }
  }

  checkRapidInveriteRequestStatus(guid: string) {
    this.apiService.checkRapidInveriteRequestStatus(guid).subscribe(async (res: any) => {
      this.client_banks = await this.getAllClientBanks(this.data?.applicationId, 'primary');
    })
  }

  loadSubmitResult() {
    this.btnSubmitSpinner = true;
    this.btnSubmitShowText = false;
  }

  resultSubmitCompleted() {
    this.btnSubmitSpinner = false;
    this.btnSubmitShowText = true;
  }

  verfiedToCompleted(status: string) {
    return status === 'Verified' ? 'Completed' : status;
  }

  onClose() {
    if (this.data?.confirmClose) {
      if (confirm("Do you want to close this interface?")) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.bankDataSubscription?.unsubscribe();
  }
}

