import { Component, OnInit, Inject, Optional, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss']
})
export class ReferralModalComponent implements OnInit, AfterViewInit {

  referral_agency = new FormGroup({
    company_id: new FormControl('', Validators.required),
    agent_name: new FormControl('')
  });

  agencies: any;
  constructor(public dialogRef: MatDialogRef<ReferralModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiServiceService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.apiService.rapidReferralAgencies().subscribe(res => {
      this.agencies = res;
    })
  }

  onOK() {
    this.dialogRef.close(this.referral_agency.value);
  }

}

