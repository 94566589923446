<nav class="navbar navbar-expand-lg bg-body-tertiary" *ngIf="!hideNavBar">
    <div class="container-fluid">
        <span class="navbar-brand"><img src="/assets/images/lmcredit.png" width="60" height="25"></span>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="viewLink" aria-current="page" [routerLink]="['/dashboard']">Rapid</a>
                </li>
            </ul>
            <div class="d-flex">
                {{userName}} &nbsp;
                <a class="nav-link" style="font-size: medium;" [routerLink]="['/login']" (click)="logout()">Sign
                    Out</a>
                &nbsp;
                <sub>{{appVersion}}</sub>
            </div>
        </div>
    </div>
</nav>