import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConsentModalComponent } from './consent-modal/consent-modal.component';
import { TransunionModalComponent } from './transunion-modal/transunion-modal.component';

@Component({
  selector: 'app-rapid-interface',
  templateUrl: './rapid-interface.component.html',
  template: '<app-add-rapid-clients></app-add-rapid-clients>',
  styleUrls: ['./rapid-interface.component.scss']
})
export class RapidInterfaceComponent implements OnInit, AfterViewInit {
  isStep1Completed: boolean = false;
  isStep2Completed: boolean = false;
  isLinear: boolean = true;
  @ViewChild('stepper')
  stepper!: MatStepper;

  isCheckAll: boolean = true;

  prequalify_form = new FormGroup({
    income_1200: new FormControl(false),
    residency: new FormControl(false),
    employed: new FormControl(false),
    bankruptcy: new FormControl(false),
    reside_quebec: new FormControl(false),
    native_reserve: new FormControl(false),
    payday_loans: new FormControl(true),
    nsfs: new FormControl(true),
    not_self_employed: new FormControl(true)
  });

  client_form = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    dob: new FormControl('', Validators.required),
    email_address: new FormControl(''),
    telephone: new FormControl('', Validators.required),
    postal_code: new FormControl('', Validators.required),
    loan_amount: new FormControl('', Validators.required),
    pull_consent: new FormControl(false),
    middle_name: new FormControl(''),
    sin: new FormControl(''),
    street: new FormControl(''),
    city: new FormControl(''),
    province: new FormControl(''),
    referral_agent_ext: new FormControl(''),
    comments: new FormControl(''),
    payment_method: new FormControl(''),
    loyalty_program: new FormControl(''),
    home_owner: new FormControl('', Validators.required),
    edp_number: new FormControl('')
  })

  allComplete: boolean = false;
  disablePrequalifyBtn: boolean = false;
  receivedRapidData: any;
  triggerNotPrequalified: boolean = false;

  checkAllBtnText = 'Check All';
  loginInfo: any;
  isConsentDisbable = false;
  disableClientBtn = false;

  TU_Report: any;
  approval_status = false;
  conditional_approval = false;
  soft_decline = false;
  hard_decline = false;
  approved_amount: any;
  requested_amount: any;
  triggerResultMessage = false;
  insertedIDs: any;
  noHit: boolean = false;
  loanConfig: any;

  btnSpinner = false;
  btnShowText = true;

  addMoreDetails: boolean = false;
  isInternal: boolean = false;
  internalData: any;
  internalReferralData: any;
  referralAgency: any;

  emailRequired: boolean = true;
  edpNumberRequired: boolean = false;

  constructor(private apiService: ApiServiceService, private router: Router, public dialog: MatDialog, private _route: ActivatedRoute
  ) { }
  ngAfterViewInit(): void {
    if (localStorage.getItem('SESSIONID')) {
      this.apiService
        .verifyRapidSession(localStorage.getItem('SESSIONID'))
        .subscribe(async (res: any) => {
          if (res.status) {
            if (res.data.length !== 0) {
              this.loginInfo = res?.data[0];
              this.loanConfig = await this.getRapidLoanConfig();
              this.getRapidUIConfig(this.loginInfo?.department_id);
              // console.log(this.loanConfig)
            } else {
              this.router.navigate(['login']);
            }
          } else {
            this.router.navigate(['login']);
          }
        })
    } else {
      this.router.navigate(['login']);
    }
  }

  async ngOnInit(): Promise<void> {
    this._route.queryParams.subscribe(params => {
      Object.entries(params).length > 0 ? this.isInternal = true : this.isInternal = false;
      this.internalData = params;
    })
    this.internalReferralData = this.isInternal === true ? await this.getReferralAgencyZOHOID(this.internalData) : {};
  }

  getRapidLoanConfig() {
    return new Promise((resolve, reject) => {
      this.apiService.getRapidLoanConfig().subscribe((res) => {
        resolve(res);
      })
    })
  }

  updateAllComplete() {
    this.allComplete = (Object.values(this.prequalify_form.value)).every(Boolean);
    this.disablePrequalifyBtn = true;
    this.isStep1Completed = true;
    this.prequalify_form.disable();
    this.matStepActivate();
  }

  matStepActivate() {
    if (this.allComplete) {
      this.triggerMatStep();
    } else {
      // console.log(this.allComplete)
      this.isStep2Completed = true;
      this.triggerNotPrequalified = true;
      setTimeout(() => {
        this.stepper.selectedIndex = 2;
      }, 100);
      this.client_form.disable();
    }
  }

  checkAll() {
    if (this.isCheckAll) {
      this.checkAllBtnText = "Uncheck All";
      Object.keys(this.prequalify_form.controls).forEach(key => {
        this.prequalify_form.controls[key].setValue(true);
      })
    } else {
      this.checkAllBtnText = "Check All";
      Object.keys(this.prequalify_form.controls).forEach(key => {
        this.prequalify_form.controls[key].setValue(false);
      })
    }
  }

  submitData() {
    if (this.emailRequired && !this.validateEmail(this.client_form.controls['email_address'].value)) {
      alert("Please enter valid email address!");
    } else if (!this.validatePhoneNumber(this.client_form.controls['telephone'].value)) {
      alert("Please enter valid mobile number!");
    } else if (this.isConsentDisbable) {
      this.loadResult();
      this.apiService
        .getRapidTransunionCreditReport(this.client_form.value, this.loginInfo)
        .subscribe(async (res) => {
          this.TU_Report = res;
          // console.log('Transunion Credit Report:');
          // console.log(this.TU_Report.REL4Report.TU_FFR_Report[0]);
          if (this.isHit(this.TU_Report)) {
            this.disableClientBtn = true;
            const info = { prequalify: this.prequalify_form.value, client: this.client_form.value, loginInfo: this.loginInfo, internalReferralData: this.internalReferralData };
            this.requested_amount = this.client_form.controls['loan_amount'].value;
            this.client_form.disable();
            this.receivedRapidData = info;

            const getInsertedTransID = await this.addTransunionClientData(this.client_form.value, this.TU_Report, this.loginInfo);
            const getInsertedClientID = await this.addRapidClient(info, false, this.loginInfo);

            this.insertedIDs = { getInsertedClientID, getInsertedTransID };

            this.triggerResultMessage = true;
            this.isStep2Completed = true;
            this.triggerMatStep();
            this.resultCompleted();
          } else {
            this.resultCompleted();
            this.openTransunionModal();
            // alert('No Hit was found. Please make sure the informations are correct!');

          }
        });
    } else {
      alert('Consent was not granted!');
    }
  }

  isHit(TU_Report: any) {
    try {
      if (TU_Report.REL4Report.TU_FFR_Report[0].Hit[0] == 'Y') return true;
      else return false;
    } catch (err) {
      return false;
    }
  }

  addRapidClient(info: any, fileStatus: boolean, userInfo: any) {
    return new Promise((resolve, reject) => {
      this.apiService.addRapidClient(info, fileStatus, userInfo).subscribe((res) => {
        resolve(res);
      })
    });
  }

  // updateRapidClientApplication(info: any, fileStatus: boolean) {
  //   return new Promise((resolve, reject) => {
  //     this.apiService.updateRapidClientApplication(info, fileStatus).subscribe((res) => {
  //       resolve(res);
  //     })
  //   })
  // }

  addTransunionClientData(info: any, TU_Report: any, userInfo: any) {
    return new Promise((resolve, reject) => {
      this.apiService.addTransunionClientData(info, TU_Report, userInfo).subscribe((res) => {
        resolve(res);
      })
    });
  }

  loadResult() {
    this.btnSpinner = true;
    this.btnShowText = false;
  }

  resultCompleted() {
    this.btnSpinner = false;
    this.btnShowText = true;
  }

  triggerMatStep() {
    setTimeout(() => {
      this.stepper.next();
    }, 100);
  }

  openConsentModal() {
    const dialogRef = this.dialog.open(ConsentModalComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.client_form.controls['pull_consent'].setValue(result);
      this.isConsentDisbable = result;
    });
  }
  validatePhoneNumber = (input_str: any) => {
    var phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneRegex.test(input_str);
  }

  validateEmail = (input_str: any) => {
    var emailRegex = /^([a-zA-Z0-9.~^+=_%#!&?*-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return emailRegex.test(input_str);
  }

  getReferralAgencyZOHOID(internalData: any) {
    return new Promise((resolve, reject) => {
      this.apiService.rapidReferralAgencies().subscribe((res: any) => {
        this.referralAgency = res.filter((item: any) => item?.['company_id'] === parseInt(internalData?.company_id));
        resolve(Object.assign(this.referralAgency[0], internalData));
      })
    })
  }

  openTransunionModal() {
    const dialogRef = this.dialog.open(TransunionModalComponent, {
      width: '500px',
      data: {
        appInfo: this.client_form.value
      },
      disableClose: true
    });
  }

  preventDateType(e: any) {
    if (e.key !== "Delete" && e.key !== "Backspace")
      e.preventDefault();
  }

  allowOnlyNumbers(e: any) {
    // Only allow if the e.key value is a number or if it's 'Backspace'
    if (isNaN(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  }

  donotAllowPaste(e: any) {
    const ctrl = e.ctrlKey ? e.ctrlKey : ((e.keyCode === 17) ? true : false);

    if (ctrl && e.keyCode === 86)
      e.preventDefault();
  }

  addressColor: boolean = false;
  isHomeowner(e: any) {
    this.addMoreDetails = e.value === 'Yes' ? true : false;
    this.addressColor = e.value === 'Yes' ? true : false;
    const validators = [Validators.required];
    if (e.value === 'Yes') {
      this.client_form.get('street')?.setValidators(validators);
      this.client_form.get('city')?.setValidators(validators);
      this.client_form.get('province')?.setValidators(validators);
    } else {
      this.client_form.get('street')?.clearValidators();
      this.client_form.get('city')?.clearValidators();
      this.client_form.get('province')?.clearValidators();
    }
    this.client_form.get('street')?.updateValueAndValidity();
    this.client_form.get('city')?.updateValueAndValidity();
    this.client_form.get('province')?.updateValueAndValidity();
  }

  getRapidUIConfig(departmentID: number) {
    this.apiService.getRapidUIConfig(departmentID).subscribe((res: any) => {
      return new Promise((resolve, reject) => {
        if (res?.data.length > 0) {
          this.emailRequired = res?.data.filter((item: any) => item?.control_name === 'email_address' && item?.is_mandatory === false).length > 0 ? false : true;
          this.edpNumberRequired = res?.data.filter((item: any) => item?.control_name === 'edp_number' && item?.is_mandatory === true).length > 0 ? true : false;
          this.conditionalValidate(this.emailRequired, 'email_address');
          this.conditionalValidate(this.edpNumberRequired, 'edp_number');
          resolve(true);
        } else {
          this.conditionalValidate(this.emailRequired, 'email_address');
          this.conditionalValidate(this.edpNumberRequired, 'edp_number');
          // this.resetValidate('email_address');
          // this.resetValidate('edp_number');
          resolve(true);
        }
      })
    })
  }

  conditionalValidate(isMandatory: boolean, controlName: any) {
    const validators = [Validators.required];
    if (isMandatory) {
      this.client_form.get(controlName)?.setValidators(validators);
    } else {
      this.client_form.get(controlName)?.clearValidators();
    }
    this.client_form.get(controlName)?.updateValueAndValidity();
  }

  // resetValidate(controlName: any) {
  //   const validators = [Validators.required];
  //   this.client_form.get(controlName)?.setValidators(validators);
  //   this.client_form.get(controlName)?.updateValueAndValidity();
  // }

}


