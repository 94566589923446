import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {

  hideNavBarUrls = ['login'];
  hideNavBar: any;
  currentRouteURL: any;
  currentAfterRedirectsURL: any;
  ignoreBodyPadding: any;
  ignoreBodyPaddingUrls: any;
  userName: string = '';

  appVersion = environment.version;

  constructor(private router: Router, private apiService: ApiServiceService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const { url, urlAfterRedirects } = event;
        this.currentRouteURL = url;
        this.currentAfterRedirectsURL = urlAfterRedirects;
        this.updateNavBarVisibility();
        this.getUserName();
        // console.log('Navigated to: ', url);
        // console.log('After Redirects to: ', urlAfterRedirects);
      }
    });
  }

  getUserName() {
    if (localStorage.getItem('SESSIONID')) {
      this.apiService
        .verifyRapidSession(localStorage.getItem('SESSIONID'))
        .subscribe((res: any) => {
          if (res.status) {
            if (res.data.length !== 0) {
              this.userName = res?.data[0]?.full_name.split(' ')[0];
            } else {
              this.router.navigate(['login']);
            }
          } else {
            this.router.navigate(['login']);
          }
        })
    } else {
      this.router.navigate(['login']);
    }
  }

  ngOnInit(): void {
  }

  updateNavBarVisibility() {
    this.hideNavBar = !!this.hideNavBarUrls.find(
      (navUrl: string) =>
        this.currentRouteURL.includes(navUrl) ||
        this.currentAfterRedirectsURL.includes(navUrl)
    );
  }

  logout() {
    localStorage.removeItem('SESSIONID');
  }


}
