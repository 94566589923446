<h1 mat-dialog-title>Referral Agency</h1>
<div mat-dialog-content>
    <form [formGroup]="referral_agency">
        <mat-form-field style="width: 100%;">
            <mat-label>Agency Name</mat-label>
            <mat-select formControlName="company_id" required>
                <mat-option *ngFor="let agency of agencies" [value]="agency.company_id">
                    {{agency.company_name}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="referral_agency.controls['company_id'].hasError('required') && referral_agency.controls['company_id'].touched">
                Required
            </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Agent Name</mat-label>
            <input matInput formControlName="agent_name" />
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button style="font-weight: bold; color: #3F51B5;" [disabled]="referral_agency.invalid"
        (click)="onOK()">Next</button>
</div>