import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RapidInterfaceComponent } from './rapid-interface/rapid-interface.component';
import { ViewRapidClientsComponent } from './rapid-interface/view-rapid-clients/view-rapid-clients.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth/auth.guard';

type PathMatch = 'full' | 'prefix' | undefined;

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full' as PathMatch, // Direct to login page
  },
  {
    path: 'application',
    canActivate: [AuthGuard], // To authenticate session
    component: RapidInterfaceComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard], // To authenticate session
    component: ViewRapidClientsComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
