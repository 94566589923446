<mat-stepper class="mat-container" [linear]="true" #stepper>
    <mat-step [completed]=" isStep1Completed">
        <h2>Complete the following prequalifying questions:</h2>
        <form [formGroup]="prequalify_form">
            <ng-template matStepLabel>Prequalifying</ng-template>
            <div style="display: flex; flex-direction: column; gap: 0.7em;">
                <mat-checkbox formControlName="income_1200">Do you earn at least $1200 per month?</mat-checkbox>
                <mat-checkbox formControlName="residency">Are you currently a Canadian Citizen or Permanent
                    Resident?</mat-checkbox>
                <mat-checkbox formControlName="employed">Are you currently employed (Completed
                    probation)?</mat-checkbox>
                <!-- <mat-checkbox formControlName="not_self_employed">Currently not self-employed?</mat-checkbox> -->
                <mat-checkbox formControlName="bankruptcy">Not currently nor in process of being/going into Bankruptcy, Consumer Proposal or Credit
                    Counselling?</mat-checkbox>
                <mat-checkbox formControlName="reside_quebec">Do you reside outside of the province of
                    Quebec?</mat-checkbox>
                <mat-checkbox formControlName="native_reserve">Do you live outside of a native reserve?</mat-checkbox>
                <!-- <mat-checkbox formControlName="payday_loans">No active payday loans?</mat-checkbox> -->
                <!-- <mat-checkbox formControlName="nsfs">Do you have less than three NSF's in the last 90days?</mat-checkbox> -->
            </div>
            <br>
            <button style="width: 13%;" mat-raised-button color="primary" (click)="checkAll()"
                (click)="isCheckAll = !isCheckAll" [disabled]="disablePrequalifyBtn">{{checkAllBtnText}}</button> &nbsp;
            <button mat-raised-button color="primary" (click)="updateAllComplete()"
                [disabled]="disablePrequalifyBtn">Next</button>
        </form>
    </mat-step>
    <mat-step [completed]="isStep2Completed">
        <ng-template matStepLabel>Application</ng-template>
        <h2 class="sample-step-title">Please fill out the following details</h2>
        <div class="row" [formGroup]="client_form">
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="first_name" required />
                        <mat-error *ngIf="
                client_form.controls['first_name'].hasError(
                                    'required'
                                ) && client_form.controls['first_name'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="last_name" required />
                        <mat-error *ngIf="client_form.controls['last_name'].hasError(
                                    'required'
                                ) && client_form.controls['last_name'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Loyalty Program?</mat-label>
                        <mat-select formControlName="loyalty_program" required>
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="client_form.controls['loyalty_program'].hasError(
                                    'required'
                                ) && client_form.controls['loyalty_program'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Date of Birth</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob"
                            (keydown)="preventDateType($event)" required>
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="client_form.controls['dob'].hasError(
                                    'required'
                                ) && client_form.controls['dob'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label *ngIf="emailRequired">Email *</mat-label>
                        <mat-label *ngIf="!emailRequired">Email (optional)</mat-label>
                        <input type="email" matInput formControlName="email_address" />
                        <mat-error *ngIf="client_form.controls['email_address'].hasError(
                                    'required'
                                ) && client_form.controls['email_address'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Are you a homeowner?</mat-label>
                        <mat-select formControlName="home_owner" (selectionChange)="isHomeowner($event)" required>
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-hint>If Yes, enter the address, city and province</mat-hint>
                        <mat-error *ngIf="client_form.controls['home_owner'].hasError(
                                    'required'
                                ) && client_form.controls['home_owner'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Mobile Phone</mat-label>
                        <input matInput formControlName="telephone" (keydown)="donotAllowPaste($event)"
                            (keypress)="allowOnlyNumbers($event)" required />
                        <mat-hint>Numbers only</mat-hint>
                        <mat-error *ngIf="client_form.controls['telephone'].hasError(
                                    'required'
                                ) && client_form.controls['telephone'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Postal Code</mat-label>
                        <input matInput formControlName="postal_code" required />
                        <mat-error *ngIf="client_form.controls['postal_code'].hasError(
                                    'required'
                                ) && client_form.controls['postal_code'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Loan Amount</mat-label>
                        <input matInput type="number" class="example-right-align" placeholder="0"
                            formControlName="loan_amount" [min]="loanConfig?.min_loan" [max]="loanConfig?.max_loan"
                            required>
                        <mat-error *ngIf="client_form.controls['loan_amount'].hasError(
                                    'required'
                                ) && client_form.controls['loan_amount'].touched
                            ">
                        </mat-error>
                        <mat-error *ngIf="client_form.controls['loan_amount'].value < loanConfig?.min_loan">
                            Loan amount must be greater than {{loanConfig?.min_loan}}
                        </mat-error>
                        <mat-error *ngIf="client_form.controls['loan_amount'].value > loanConfig?.max_loan">
                            Loan amount must be less than {{loanConfig?.max_loan}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label *ngIf="edpNumberRequired">EDP Account No. *</mat-label>
                        <mat-label *ngIf="!edpNumberRequired">EDP Account No. (optional)</mat-label>
                        <input matInput formControlName="edp_number" />
                        <mat-error *ngIf="client_form.controls['edp_number'].hasError(
                                    'required'
                                ) && client_form.controls['edp_number'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Collector (extension)</mat-label> <input matInput
                            formControlName="referral_agent_ext" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Payment Method (optional)</mat-label>
                        <mat-select formControlName="payment_method">
                            <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                            <mat-option value="Cheque">Cheque</mat-option>
                            <mat-option value="Cash">Cash</mat-option>
                            <mat-option value="EFT (Email Money Transfer)">EFT (Email Money Transfer)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <p (click)="addMoreDetails = !addMoreDetails" style="color: #3F51B5; cursor: pointer;">
                <span style="font-size: medium; font-weight: bold;" *ngIf="addMoreDetails===false">+ Show more
                    information</span>
                <span style="font-size: medium; font-weight: bold;" *ngIf="addMoreDetails===true">- Hide more
                    information</span>
            </p>
            <div class="row" *ngIf="addMoreDetails">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label [ngClass]="{'homeowner-address':addressColor == true}">Address</mat-label>
                        <input matInput formControlName="street" />
                        <mat-error *ngIf="client_form.controls['street'].hasError(
                                    'required'
                                ) && client_form.controls['street'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label [ngClass]="{'homeowner-address':addressColor == true}">City</mat-label>
                        <input matInput formControlName="city" />
                        <mat-error *ngIf="client_form.controls['city'].hasError(
                                    'required'
                                ) && client_form.controls['city'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label [ngClass]="{'homeowner-address':addressColor == true}">Province</mat-label>
                        <mat-select formControlName="province">
                            <mat-option value="ON">Ontario</mat-option>
                            <mat-option value="NS">Nova Scotia</mat-option>
                            <mat-option value="NB">New Brunswick</mat-option>
                            <mat-option value="MB">Manitoba</mat-option>
                            <mat-option value="BC">British Columbia</mat-option>
                            <mat-option value="PEI">Prince Edward Island</mat-option>
                            <mat-option value="SK">Saskatchewan</mat-option>
                            <mat-option value="AB">Alberta</mat-option>
                            <mat-option value="NL">Newfoundland and Labrador</mat-option>
                            <mat-option value="NT">Northwest Territories</mat-option>
                            <mat-option value="NU">Nunavut</mat-option>
                            <mat-option value="YT">Yukon</mat-option>
                        </mat-select>
                        <mat-error *ngIf="client_form.controls['province'].hasError(
                                    'required'
                                ) && client_form.controls['province'].touched
                            ">
                            Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="addMoreDetails">
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Middle Name (optional)</mat-label>
                        <input matInput formControlName="middle_name" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>SIN (optional)</mat-label>
                        <input matInput formControlName="sin" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Comments (optional)</mat-label>
                        <textarea matInput formControlName="comments" rows="1"></textarea>
                    </mat-form-field>
                </div>
                <!-- <div class="col">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Payment Method</mat-label>
                        <mat-select formControlName="payment_method">
                            <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                            <mat-option value="Cheque">Cheque</mat-option>
                            <mat-option value="Cash">Cash</mat-option>
                            <mat-option value="EFT (Email Money Transfer)">EFT (Email Money Transfer)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>
            <!-- <div class="row" *ngIf="addMoreDetails">
                <div class="col-4">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Collector (extension)</mat-label> <input matInput
                            formControlName="referral_agent_ext" />
                    </mat-form-field>
                </div>
                <div class="col-8">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Comments</mat-label>
                        <textarea matInput formControlName="comments" rows="1"></textarea>
                    </mat-form-field>
                </div>
            </div> -->
            <div class=" row">
                <div class="col">
                    <mat-checkbox formControlName="pull_consent"
                        (change)="isConsentDisbable = !isConsentDisbable">Consent
                        to pull
                        credit</mat-checkbox><mat-icon style="cursor: pointer;"
                        (click)="openConsentModal()">info</mat-icon>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button mat-raised-button color="primary" style="width: 10%; margin-top: 20px;"
                        (click)="submitData()"
                        [disabled]="client_form.invalid || !isConsentDisbable || disableClientBtn">
                        <span *ngIf="btnSpinner" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        <span *ngIf="btnShowText">Submit</span>
                    </button>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Result</ng-template>
        <app-rapid-result [receivedRapidData]="receivedRapidData" [loginInfo]="loginInfo"
            [triggerResultMessage]="triggerResultMessage" [triggerNotPrequalified]="triggerNotPrequalified"
            [insertedIDs]="insertedIDs" [TU_Report]="TU_Report" [emailRequired]="emailRequired"></app-rapid-result>
    </mat-step>
</mat-stepper>