<div style="position: relative;">
    <mat-icon style="cursor: pointer; position: absolute; right: 0; top:0; z-index: 2;"
        (click)="onClose()">close</mat-icon>
</div>
<div class="row" [formGroup]="inverite_form">
    <p><strong>First name</strong>: {{data?.firstName}}&nbsp;&nbsp;|&nbsp;&nbsp;<strong>Last name</strong>:
        {{data?.lastName}}&nbsp;&nbsp;|&nbsp;&nbsp;
        <strong>Email</strong>: {{data?.email}}
    </p>
    <div class="row" *ngIf="isSourceButton">
        <div class="col-5">
            <mat-form-field style="width: 100%;">
                <mat-label>Bank name</mat-label>
                <mat-select formControlName="bank_names">
                    <mat-option *ngFor="let bank of banking_list;" [value]="bank.bankID">{{ bank.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="
                inverite_form.controls['bank_names'].hasError(
                                    'required'
                                ) && inverite_form.controls['bank_names'].touched
                            ">
                    Required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field style="width: 100%;">
                <mat-label>Province</mat-label>
                <mat-select formControlName="province">
                    <mat-option *ngFor="let province of province_names"
                        [value]="province.provinceID">{{province.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="
                inverite_form.controls['province'].hasError(
                                    'required'
                                ) && inverite_form.controls['province'].touched
                            ">
                    Required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3" style="position: relative;">
            <button style="position:absolute; bottom: 31%; width: 100%;" mat-raised-button color="primary"
                (click)="submitBankRequest()" [disabled]="inverite_form.invalid">
                <span *ngIf="btnSubmitSpinner" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="btnSubmitShowText">Submit Request</span></button>
        </div>
    </div>
    <hr style="margin-bottom: 5px;">
    <table>
        <thead>
            <th>#</th>
            <th>Bank Name</th>
            <th>Province</th>
            <th>Status</th>
            <th *ngIf="isSourceButton">Action</th>
        </thead>
        <tbody>
            <mat-spinner *ngIf="isloading" [diameter]="20"></mat-spinner>
            <tr *ngFor="let banking of client_banks; let i = index">
                <td>{{i+1}}</td>
                <td>{{banking?.bank_name}}</td>
                <td>{{banking?.province}}</td>
                <td>{{verfiedToCompleted(banking?.status)}}<button *ngIf="banking?.status !== 'Verified'"
                        mat-icon-button><mat-icon
                            (click)="checkRapidInveriteRequestStatus(banking?.guid)">refresh</mat-icon></button>
                <td *ngIf="isSourceButton"><button mat-icon-button
                        (click)="deleteGuid(banking?.guid)"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>