import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  uri: string = environment.DO_URL;
  loggedInStatus = false;

  constructor(private http: HttpClient) { }
  testingRapid() {
    return this.http.get(this.uri + '/testingRapid');
  }
  verify_signin(something: any) {
    const data = {
      info: something,
    };
    return this.http.post(this.uri + '/rapid-signin', data);
  }
  updateSession(session: any) {
    const data = {
      info: session,
    };
    return this.http.post(this.uri + '/updatesession', data);
  }
  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
  }
  verifyRapidSession(something: any) {
    const data = {
      info: something,
    };
    return this.http.post(this.uri + '/verifyRapidSession', data);
  }
  addRapidClient(something: any, fileStatus: boolean, userInfo: any) {
    const data = {
      info: something,
      fileStatus: fileStatus,
      userInfo: userInfo
    };
    return this.http.post(this.uri + '/addRapidClient', data);
  }
  updateRapidClientApplication(something: any, fileStatus: boolean, rapidStatus: any, other: any) {
    const data = {
      info: something,
      fileStatus: fileStatus,
      rapidStatus: rapidStatus,
      other: other
    };
    return this.http.post(this.uri + '/updateRapidClientApplication', data);
  }
  getAllRapidClients(userInfo: any) {
    const data = {
      userInfo: userInfo
    }
    return this.http.post(this.uri + '/getAllRapidClients', data);
  }
  getRapidTransunionCreditReport(model: any, loginInfo: any) {
    const data = {
      model: model,
      loginInfo: loginInfo
    };
    return this.http.post(this.uri + '/getRapidTransunionCreditReport', data);
  }
  preapprove(model: any, TU_Report: any) {
    const data = {
      model: model,
      TU_Report: TU_Report,
    };
    return this.http.post(this.uri + '/preapprove', data);
  }
  createRapidZohoApplication(model: any, approval_status: boolean, creditScore: any, rapid_status: any, result: any) {
    const data = {
      model: model,
      approval_status: approval_status,
      creditScore: creditScore,
      rapid_status: rapid_status,
      result: result
    };
    return this.http.post(this.uri + '/createRapidZohoApplication', data);
  }
  addTransunionClientData(info: any, TU_Report: any, userInfo: any) {
    const data = {
      info: info,
      TU_Report: TU_Report,
      userInfo: userInfo
    };
    return this.http.post(this.uri + '/addTransunionClientData', data);
  }
  updateTransunionApplication(info: any) {
    const data = {
      info: info
    };
    return this.http.post(this.uri + '/updateTransunionApplication', data);
  }
  updateRevisedRapidAmount(rapidID: any, loanAmount: any) {
    const data = {
      rapidID: rapidID,
      loanAmount: loanAmount
    };
    return this.http.post(this.uri + '/updateRevisedRapidAmount', data);
  }
  updateRapidZohoApplication(something: any, applicationID: any, loanAmount: any) {
    const data = {
      info: something,
      appID: applicationID,
      loanAmount: loanAmount
    };
    return this.http.post(this.uri + '/updateRapidZohoApplication', data);
  }
  rapidApprovalResult(loan_amount: any, TU_Report: any) {
    const data = {
      loan_amount: loan_amount,
      TU_Report: TU_Report,
    };
    return this.http.post(this.uri + '/rapidApprovalResult', data);
  }
  getRapidLoanConfig() {
    return this.http.get(this.uri + '/getRapidLoanConfig');
  }
  rapidScoringModel(loan_amount: any, TU_Report: any, collectorInputs: any) {
    const data = {
      loan_amount: loan_amount,
      TU_Report: TU_Report,
      collectorInputs: collectorInputs
    };
    return this.http.post(this.uri + '/rapidScoringModel', data);
  }

  // WORK DRIVE
  createRapidFolder(folderName: any) {
    const data = {
      folderName: folderName
    };
    return this.http.post(this.uri + '/createRapidFolder', data);
  }

  updateRapidZohoWorkDrive(applicationID: any, folderID: any) {
    const data = {
      applicationID: applicationID,
      folderID: folderID
    };
    return this.http.post(this.uri + '/updateRapidZohoWorkDrive', data);
  }

  createExternalWorkDriveLink(folderID: any, clientEmail: any) {
    const data = {
      folderID: folderID,
      clientEmail: clientEmail
    };
    return this.http.post(this.uri + '/createExternalWorkDriveLink', data);
  }

  rapidReferralAgencies() {
    return this.http.get(this.uri + '/rapidReferralAgencies');
  }

  getRecentNotes(zoho_app_id: string) {
    const data = { zoho_app_id: zoho_app_id };
    return this.http.post(this.uri + '/getRecentNote', data);
  }

  getDepartmentDropdown(roleID: number, companyID: number) {
    const data = { roleID, companyID };
    return this.http.post(this.uri + '/getDepartmentDropdown', data);
  }

  getDepartmentEmployees(department_name: string) {
    const data = { department_name };
    return this.http.post(this.uri + '/getDepartmentEmployees', data);
  }

  getRapidUIConfig(department_id: number) {
    const data = { department_id };
    return this.http.post(this.uri + '/getRapidUIConfig', data);
  }

  getInveriteBanks() {
    return this.http.get(this.uri + '/getInveriteBanks')
  }

  isExistingInverite(application_id: any, application_type: string, bank_id: number) {
    const data = {
      application_id,
      application_type,
      bank_id,
    };
    return this.http.post(this.uri + '/isExistingInverite', data);
  }

  createRapidInveriteRequest(requestdata: any) {
    const data = { requestdata };
    return this.http.post(this.uri + '/createRapidInveriteRequest', data);
  }

  updateRapidInverite(inveriteEntry: any) {
    const data = { inveriteEntry };
    return this.http.post(this.uri + '/updateRapidInverite', data);
  }

  getAllClientBanks(application_id: any, application_type: string) {
    const data = {
      application_id,
      application_type
    };
    return this.http.post(this.uri + '/getAllClientBanks', data);
  }

  deleteGuid(guid: string) {
    const data = { guid };
    return this.http.post(this.uri + '/deleteGuid', data);
  }

  checkRapidInveriteRequestStatus(guid: string) {
    const data = { guid };
    return this.http.post(this.uri + '/checkRapidInveriteRequestStatus', data);
  }

  getBtnPermission() {
    return this.http.get(this.uri + "/getBtnPermission");
  }

  getInveritePDF(guid: any) {
    const data = { guid };
    return this.http.post(this.uri + '/downloadInveriteReport', data);
  }
}
