import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transunion-modal',
  templateUrl: './transunion-modal.component.html',
  styleUrls: ['./transunion-modal.component.scss']
})
export class TransunionModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TransunionModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    // console.log(this.data, "&&&&&&&&&&&&&&&")
  }

  onOK() {
    this.dialogRef.close(true);
  }

}
