import { Component, OnInit, AfterViewInit, SimpleChanges, Input, HostListener } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/api-service.service';
import { MatDialog } from '@angular/material/dialog';
import { InveriteModalComponent } from '../inverite-modal/inverite-modal.component';

@Component({
  selector: 'app-rapid-result',
  templateUrl: './rapid-result.component.html',
  styleUrls: ['./rapid-result.component.scss']
})
export class RapidResultComponent implements OnInit, AfterViewInit {
  @Input() receivedRapidData: any; // decorate the property with 
  @Input() approval_status: any;
  @Input() conditional_approval: any;
  @Input() soft_decline: any;
  @Input() hard_decline: any;
  @Input() approved_amount: any;
  @Input() requested_amount: any;
  @Input() triggerNotPrequalified: any;
  @Input() triggerResultMessage: any;
  @Input() insertedIDs: any;
  @Input() loginInfo: any;
  @Input() TU_Report: any;
  @Input() emailRequired: any;

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.warnUnload)
      return false;
    else
      return true;
  }

  revised_amount = new FormControl();
  btnSpinner = false;
  btnShowText = true;
  loanConfig: any;

  tradesList: any;
  collectionList: any;
  hideIndex: boolean = false;
  insertedTransunionID: number | undefined;
  insertedRapidClientID: number | undefined;

  loader: boolean = false;
  hideReferral: boolean = false;
  showResult: boolean = false;
  showCard: boolean = false;

  loan_amount = new FormControl('', Validators.required);

  warnUnload: boolean = false;

  disableSubmit: boolean = false;
  disablePreLoanAmount: boolean = false;
  displayEditIcons: boolean = false;

  btnSubmitSpinner = false;
  btnSubmitShowText = true;

  zoho_app_id: any;
  approved_result_display: boolean = false;
  pending_result_display: boolean = false;

  otherCreditor: boolean = false;
  otherCreditor_name = new FormControl('');
  otherCreditor_amount = new FormControl(null);

  externalLinkBtn: boolean = false;
  externalLinkBtnText = "Email file upload link";
  btnLinkSpinner = false;
  btnLinkShowText = true;
  bankingRequired: boolean = false;

  isBankingPermittedAgency: boolean | undefined;

  constructor(private apiService: ApiServiceService, public dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes!.receivedRapidData) {
      // console.log(this.receivedRapidData, "%%%%%%%%%%%%%%%%%%%%%%")
      this.loan_amount.setValue(this.receivedRapidData?.client?.loan_amount);
      if (typeof this.receivedRapidData !== 'undefined') {
        this.warnUnload = true;
        // console.log(this.warnUnload)
      }
    }

    if (changes!.TU_Report) {
      // console.log(this.TU_Report, "TU_Report_Approval%%%%%%%%%%%%%%%%%%%%%%")
      this.getTrades();
    }

    if (changes!.triggerResultMessage) {
      // console.log(this.triggerResultMessage, "%%%%%%%%%%%%%%%%%%%%%%")
    }

    if (changes!.triggerNotPrequalified) {
      // console.log(this.triggerNotPrequalified, "*****")
    }

    if (changes!.insertedIDs) {
      // console.log(this.insertedIDs, "insertedIDs*****")
      this.insertedTransunionID = this.insertedIDs?.getInsertedTransID?.insertedTransunionID;
      this.insertedRapidClientID = this.insertedIDs?.getInsertedClientID?.insertedRapidClientID;
    }
  }
  ngAfterViewInit(): void {
    this.apiService.getRapidLoanConfig().subscribe((res) => {
      this.loanConfig = res;
    })
  }

  ngOnInit(): void {

  }

  async applyRevisedAmount() {
    this.loadResult();
    let resultR = await this.updateRapidAmount(this.revised_amount.value);
    let resultZ = await this.updateZOHOAmount();
    this.approval_status = true;
    this.approved_result_display = true;
    this.pending_result_display = false;
    this.conditional_approval = false;
    this.resultCompleted();
    if (this.externalLinkBtn) {
      this.externalLinkBtnText = "File upload email already been sent";
    } else {
      this.externalLinkBtnText = "Email file upload link";
    }
  }

  updateRapidAmount(amount: any) {
    return new Promise((resolve, reject) => {
      this.apiService.updateRevisedRapidAmount(this.insertedRapidClientID, amount).subscribe((res) => {
        // console.log(res, "DB&&&&&&&&&&&&&&&")
        this.receivedRapidData.client.loan_amount = amount;
        resolve(true);
      })
    })
  }

  updateZOHOAmount() {
    return new Promise((resolve, reject) => {
      this.apiService.updateRapidZohoApplication(this.receivedRapidData, this.zoho_app_id, this.revised_amount.value).subscribe((res) => {
        // console.log(res, "ZOHO&&&&&&&&&&&&&&&")
        resolve(true);
      })
    })
  }

  loadResult() {
    this.btnSpinner = true;
    this.btnShowText = false;
  }

  resultCompleted() {
    this.btnSpinner = false;
    this.btnShowText = true;
  }

  loadSubmitResult() {
    this.btnSubmitSpinner = true;
    this.btnSubmitShowText = false;
  }

  resultSubmitCompleted() {
    this.btnSubmitSpinner = false;
    this.btnSubmitShowText = true;
  }

  getTrades() {
    this.tradesList = this.TU_Report?.REL4Report.TU_FFR_Report[0]?.Trades?.[0]?.Trade;
    this.collectionList = this.TU_Report?.REL4Report.TU_FFR_Report[0]?.Collections?.[0]?.Collection;
    this.addDefaultCheckboxes(this.tradesList, this.collectionList);
  }

  // tier1Status: boolean = false;
  rapid_status = 'Declined';
  fallBackStatus: boolean = false;
  fallBackAmount = 0;
  tier2Status: boolean = false;
  declineMessage = '';
  knockoutStatus: boolean = false;
  submitRapidApproval() {
    // this.loader = true;
    this.getBankingPerimission(); // Validate banking permission

    this.disablePreLoanAmount = true;
    this.loadSubmitResult();
    const collectorInputs = {
      postal_code: this.receivedRapidData?.client?.postal_code,
      loyalty_program: this.receivedRapidData?.client.loyalty_program,
      home_owner: this.receivedRapidData?.client.home_owner,
      creditor_text: this.otherCreditor_name.value
    }
    this.apiService.rapidScoringModel(this.receivedRapidData?.client?.loan_amount, this.TU_Report, collectorInputs).subscribe(async (res: any) => {
      // console.log(res,"_______________________")
      let creditScore = this.TU_Report.REL4Report.TU_FFR_Report[0];
      const emailResult = res?.result;
      this.bankingRequired = res?.bankingRequired;
      if (res?.result?.hasOwnProperty('knockout')) {
        this.knockoutStatus = true;
        this.approval_status = false;
        this.rapid_status = 'Declined';
        this.declineMessage = res?.result?.knockout;
      } else if (res?.result?.hasOwnProperty('pending')) {
        this.approval_status = false;
        this.rapid_status = 'Pending';
        this.declineMessage = res?.result?.pending;
        this.tier2Status = true;
      } else {
        if (res?.result?.tierRange?.tier1Approved) {
          this.approval_status = true;
          this.rapid_status = 'Approved';
          this.externalLinkBtnText = "Email file upload link";
        }
        if (!res?.result?.tierRange?.tier1Approved && res?.result?.tierRange?.tier1FallBackApproved) {
          this.approval_status = false;
          this.rapid_status = 'Pending';
          this.fallBackStatus = true;
          this.fallBackAmount = res?.result?.tierRange?.tier1FallBackAmount;
        }
        if (!res?.result?.tierRange?.tier1Approved && res?.result?.tierRange?.tier2Approved) {
          this.approval_status = false;
          this.rapid_status = 'Pending';
          this.tier2Status = true;
        }
        if (!res?.result?.tierRange?.tier1Approved && !res?.result?.tierRange?.tier1FallBackApproved && !res?.result?.tierRange?.tier2Approved) {
          this.approval_status = false;
          this.rapid_status = 'Declined';
          this.declineMessage = "LM Credit Criteria";
        }
      }


      const otherCreditorObject = { creditor: this.otherCreditor_name.value, amount: this.otherCreditor_amount.value };
      this.zoho_app_id = await this.createRapidZohoApplication(this.receivedRapidData, this.approval_status, creditScore, this.rapid_status, emailResult);
      // this.zoho_app_id = { application_id: '2390707000034288237' };
      const resultT = await this.updateTransunionApplication({ insertedTransunionID: this.insertedTransunionID, appID: this.zoho_app_id, TU_Report: this.TU_Report });
      const resultR = await this.updateRapidClientApplication({ insertedRapidClientID: this.insertedRapidClientID, appID: this.zoho_app_id }, this.approval_status, this.rapid_status, otherCreditorObject);
      this.resultSubmitCompleted();
      this.warnUnload = false;
      this.showCard = true;
    })
  }

  async createRapidZohoApplication(model: any, approval_status: boolean, creditScore: any, rapid_status: any, result: any) {
    return new Promise((resolve, reject) => {
      this.apiService.createRapidZohoApplication(model, approval_status, creditScore, rapid_status, result).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateTransunionApplication(info: any) {
    return new Promise((resolve, reject) => {
      this.apiService.updateTransunionApplication(info).subscribe((res) => {
        resolve(res);
      })
    })
  }

  updateRapidClientApplication(info: any, fileStatus: boolean, rapidStatus: any, other: any) {
    return new Promise((resolve, reject) => {
      this.apiService.updateRapidClientApplication(info, fileStatus, rapidStatus, other).subscribe((res) => {
        resolve(res);
      })
    })
  }

  addDefaultCheckboxes(tradeList: any, collectionList: any) {
    if (tradeList) {
      if (!tradeList?.[0].hasOwnProperty('Referral')) {
        for (var i = 0; i < tradeList?.length; i++) {
          tradeList[i]['Referral'] = false;
        }
      }
      if (!tradeList?.[0].hasOwnProperty('Cons')) {
        for (var i = 0; i < tradeList?.length; i++) {
          tradeList[i]['Cons'] = false;
        }
      }
    }
    if (collectionList) {
      if (!collectionList?.[0].hasOwnProperty('Referral')) {
        for (var i = 0; i < collectionList?.length; i++) {
          collectionList[i]['Referral'] = false;
        }
      }
      if (!collectionList?.[0].hasOwnProperty('Cons')) {
        for (var i = 0; i < collectionList?.length; i++) {
          collectionList[i]['Cons'] = false;
        }
      }
    }
  }

  enableEditAmount() {
    this.disableButtons();
  }
  hideEditIcons() {
    this.enableButtons();
    this.loan_amount.setValue(this.receivedRapidData.client.loan_amount);
  }
  async updatePreLoanAmount() {
    this.receivedRapidData.client.loan_amount = this.loan_amount.value;
    let resultR = await this.updateRapidAmount(this.loan_amount.value);
    this.enableButtons();
  }

  enableButtons() {
    this.displayEditIcons = false;
    this.disableSubmit = false;
  }

  disableButtons() {
    this.displayEditIcons = true;
    this.disableSubmit = true;
  }

  // WORK DRIVE
  async createClientFileUploadLink() {
    let resultFolderID = await this.createRapidFolder();
    let resultUpdateDrive = await this.updateRapidZohoWorkDrive(this.zoho_app_id, resultFolderID);
    let resultExternalLink = await this.createExternalWorkDriveLink(resultFolderID, this.receivedRapidData?.client?.email_address);
  }

  createRapidFolder() {
    return new Promise((resolve, reject) => {
      let fullName = this.receivedRapidData?.client?.first_name.concat(' ').concat(this.receivedRapidData?.client?.last_name);
      this.apiService.createRapidFolder(fullName).subscribe((res: any) => {
        resolve(res?.folderID);
      })
    })
  }

  updateRapidZohoWorkDrive(applicationID: any, folderID: any) {
    return new Promise((resolve, reject) => {
      this.apiService.updateRapidZohoWorkDrive(applicationID, folderID).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  createExternalWorkDriveLink(folderID: any, clientEmail: any) {
    return new Promise((resolve, reject) => {
      this.loadLinkResult();
      this.apiService.createExternalWorkDriveLink(folderID, clientEmail).subscribe((res: any) => {
        if (res?.message) {
          this.externalLinkBtn = true;
          this.externalLinkBtnText = "Emailed link";
          this.resultLinkCompleted();
        }
        resolve(res?.message);
      })
    })
  }

  loadLinkResult() {
    this.btnLinkSpinner = true;
    this.btnLinkShowText = false;
  }

  resultLinkCompleted() {
    this.btnLinkSpinner = false;
    this.btnLinkShowText = true;
  }

  // ***** Inverite
  openInveriteModalButton() {
    const dialogRef = this.dialog.open(InveriteModalComponent, {
      width: '800px',
      data: {
        isButton: true,
        firstName: this.receivedRapidData?.client?.first_name,
        lastName: this.receivedRapidData?.client?.last_name,
        email: this.receivedRapidData?.client?.email_address,
        employeeId: this.loginInfo?.employeeID,
        applicationId: this.zoho_app_id?.application_id,
        phone: this.receivedRapidData?.client?.telephone,
        confirmClose: true
      },
      disableClose: true
    });
  }

  getBankingPerimission() {
    this.apiService.getBtnPermission().subscribe((res: any) => {
      const companyPermittedIds = res?.[0]?.rapid_banking;
      this.isBankingPermittedAgency = companyPermittedIds?.includes(this.loginInfo?.company_id) ? true : false;
    })
  }

}
