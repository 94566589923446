import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiServiceService } from './api-service.service';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RapidInterfaceComponent } from './rapid-interface/rapid-interface.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { ViewRapidClientsComponent } from './rapid-interface/view-rapid-clients/view-rapid-clients.component';
import { AgGridModule } from 'ag-grid-angular';
import { LoginComponent } from './login/login.component';
import { TopnavComponent } from './topnav/topnav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RapidResultComponent } from './rapid-interface/rapid-result/rapid-result.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthGuard } from './services/auth/auth.guard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConsentModalComponent } from './rapid-interface/consent-modal/consent-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ReferralModalComponent } from './rapid-interface/referral-modal/referral-modal.component';
import { TransunionModalComponent } from './rapid-interface/transunion-modal/transunion-modal.component';
import { ButtonCellRendererComponent } from './rapid-interface/button-cell-renderer/button-cell-renderer.component';
import { RecentNotesModalComponent } from './rapid-interface/recent-notes-modal/recent-notes-modal.component';
import { DatePipe } from '@angular/common';
import { ClientDetailsModalComponent } from './rapid-interface/client-details-modal/client-details-modal.component';
import { InveriteModalComponent } from './rapid-interface/inverite-modal/inverite-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    RapidInterfaceComponent,
    ViewRapidClientsComponent,
    LoginComponent,
    TopnavComponent,
    RapidResultComponent,
    ConsentModalComponent,
    ReferralModalComponent,
    TransunionModalComponent,
    ButtonCellRendererComponent,
    RecentNotesModalComponent,
    ClientDetailsModalComponent,
    InveriteModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AgGridModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule
  ],
  providers: [AuthGuard, ApiServiceService, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
