import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/api-service.service';

@Component({
  selector: 'app-recent-notes-modal',
  templateUrl: './recent-notes-modal.component.html',
  styleUrls: ['./recent-notes-modal.component.scss']
})
export class RecentNotesModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecentNotesModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiServiceService
  ) { }

  notesData: any;
  ngOnInit(): void {
    this.apiService.getRecentNotes(this.data?.applicationID).subscribe((res: any) => {
      this.notesData = res?.note !== 'No Notes!' ? res[0] : res;
    })
  }

  onOK() {
    this.dialogRef.close(true);
  }

}
