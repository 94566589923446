<h1 mat-dialog-title>LM Credit Consent</h1>
<div mat-dialog-content>
    <p style="text-align: justify;">"As part of the application process for a loan with LM CREDIT, we require your
        verbal consent to collect
        and use your personal and financial information. This consent may not be withdrawn once given.<br><br>
        <strong>Do you agree to allow us: </strong><br><br>
        To pull a credit report from the credit bureau today and during the next year, to assess your credit
        value?"
    </p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onOK()">OK</button>
</div>