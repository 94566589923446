<!-- <div style="height: 100%; background-color: #fafafa">
    <div class="login-container">
        <div class="bottomright">{{ appVersion }}</div>
        <mat-card style="padding: 30px;">
            <mat-card-title style="text-align: center;">
                <span><img src="/assets/images/lmcredit.png" width="100" height="41"></span>
                <h1
                    style="padding: 5px 5px; color:rgb(91, 90, 90); text-shadow: 1px 1px 2px black;  transform: scale(1.3, 0.8)">
                    RAPID
                </h1>
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="login_form">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="user" required />
                        <mat-error
                            *ngIf="login_form.controls['user'].hasError('required') && login_form.controls['user'].touched">
                            Required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 100%;">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="pass" required>
                        <button style="background-color: transparent; border-style: none;" mat-icon-button matSuffix
                            (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error
                            *ngIf="login_form.controls['pass'].hasError('required') && login_form.controls['pass'].touched">
                            Required
                        </mat-error>
                    </mat-form-field>
                    <button style="width: 100%;" mat-raised-button color="primary" [disabled]="login_form.invalid"
                        (click)="userLogin()">Sign in</button>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div> -->

<div style="height: 100%; background-color: #fafafa">
    <div class="login-container">
        <mat-card style="padding: 30px;">
            <div style="display: flex; flex-wrap: nowrap; gap: 2em;">
                <div style="padding-left: 20px; padding-right: 20px; margin: auto;">
                    <img src="/assets/images/lmcredit.png" width="180">
                    <h1
                        style="font-weight: bold; font-size: xx-large; text-align:center; line-height:1.8em; color: rgb(116, 116, 116);">
                        RAPID</h1>
                </div>
                <div style="margin: auto;">
                    <div class="vertical"></div>
                </div>
                <div>
                    <mat-card-title style="text-align: center;">
                        <h2 style="font-weight: normal; color: rgb(77, 77, 77);">Sign in</h2>
                    </mat-card-title>
                    <mat-card-content>
                        <form [formGroup]="login_form">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Username</mat-label>
                                <input matInput formControlName="user" required />
                                <mat-error
                                    *ngIf="login_form.controls['user'].hasError('required') && login_form.controls['user'].touched">
                                    Required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <mat-label>Password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="pass" required>
                                <button style="background-color: transparent; border-style: none;" mat-icon-button
                                    matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="login_form.controls['pass'].hasError('required') && login_form.controls['pass'].touched">
                                    Required
                                </mat-error>
                            </mat-form-field>
                            <button style="width: 100%;" mat-raised-button color="primary"
                                [disabled]="login_form.invalid" (click)="userLogin()">Sign in</button>
                        </form>
                    </mat-card-content>
                </div>
            </div>
        </mat-card>
        <div class="bottomright">{{ appVersion }}</div>
    </div>
</div>